import React from 'react'
import '../CSS/Components.css'

const Top = ({ page }) => {
  const items = [
    {
      item: 'Home',
      path: '/#/index/home'
    },
    {
      item: 'My Team',
      path: '/#/my/team'
    },
    {
      item: 'Me',
      path: '/#/mine'
    }
  ]

  return (
    <div className='main--top'>
      {items.map((item, index) => {
        return (
          <div className={page === item.item ? 'item item--active' : 'item'} key={index}>
            <center>
              <div className="item--name" onClick={() => {
                if (item.item === page) return;
                window.location.href = item.path
              }}>{item.item}</div>
              <div className="dash"></div>
            </center>
          </div>
        )
      })}
    </div>
  )
}

export default Top