import React, { useState, useEffect, useRef } from 'react'
import '../CSS/Team.css'

import axios from 'axios'
import copy from 'copy-to-clipboard';

import Header from '../Components/Header';
import Top from '../Components/Top';
import SecTitle from '../Components/SecTitle';

import { BiSolidGift } from "react-icons/bi";

import Loading from '../Components/Loading';
import Error from '../Components/Error';

const Team = ({ token, rate, symbol }) => {
    const [name, setName] = useState('XXXXX XXX');
    const [phone, setPhone] = useState('+1 ******7890');
    const [id, setId] = useState('XXXXXXXX');

    const [size, setSize] = useState(0)
    const [level, setLevel] = useState([])

    const [commission, setCommission] = useState({
        lv1: 0,
        lv2: 0,
        lv3: 0
    })

    const [recharge, setRecharge] = useState({
        lv1: 0,
        lv2: 0,
        lv3: 0
    })

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const isFirstRun = useRef(true);

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    const getData = async () => {
        setLoader(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/index/main`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data;

            setPhone(data.isd + ' ' + data.phone)
            setName(data.name)
            setId(data.id)

            setSize(data.referrals.verified)
            setLevel(data.referrals.rewards_claimed)

            let income = data.referrals.commission
            setCommission({
                lv1: income.lv1,
                lv2: income.lv2,
                lv3: income.lv3
            })

            let deposit = data.referrals.recharges
            setRecharge({
                lv1: deposit.lv1,
                lv2: deposit.lv2,
                lv3: deposit.lv3
            })
            
            setLoader(false);
        } catch (error) {
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';

            if (error.response && error.response.data.logout === true) {
                window.location.href = '/#/user/login'
                window.location.reload();
            }

            displayError(errorMessage);
        }
    };

    useEffect(() => {
        if (isFirstRun.current) {
            getData();
            isFirstRun.current = false;
        }
    }, []);

    const referralCode = id;
    const referralLink = `https://m.spx-share.com/#/user/register?inviteCode=${referralCode}`;

    const items = [2, 5, 10, 20, 50, 100, 200, 350, 700]

    function copyText(text) {
        copy(text)
        setIsError(true)
        setErrorText('Copied')

        const timerId = setTimeout(() => {
            setIsError(false)
            setErrorText('')
        }, 1500);

        return () => clearTimeout(timerId);
    }

    async function claim(level) {
        setLoader(true);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER}/claim/reward?level=${level}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            const data = response.data;

            // Update state with the new level and referral size
            setLevel(prevLevel => [...prevLevel, level]);
            setSize(data.referrals);

            // Show success message
            setLoader(false)
            displayError('Claimed Successfully');
        } catch (error) {
            setLoader(false)
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';
            displayError(errorMessage);
        }
    }

    return (
        <div className='team'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            <Header phone={phone} name={name} />
            <Top page={'My Team'} />

            <div className="section--start">
                <div className="detail--box">
                    <div className="detail--box--cover">
                        <div className="box--content">
                            <div className="box--content--top">
                                <div className="top--text">Invitation code:</div>
                                <div className="bottom--col">
                                    <div className="bottom--col--text">{referralCode}</div>
                                    <button className="copy--btn" onClick={() => copyText(referralCode)}>copy</button>
                                </div>
                            </div>
                            <div className="box--content--bottom">
                                <div className="top--text">Share your referral link and start earning</div>
                                <div className="bottom--col tp2">
                                    <div className="bottom--col--text">{referralLink}</div>
                                    <div className="copy--btn" onClick={() => copyText(referralLink)}>copy</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="referral--data">
                    <SecTitle title={'Team Data'} />

                    <div className="col--container">
                        <div className="col">
                            <div className="data--box">
                                <div className="data--box--top">
                                    <div className="box--top--left">
                                        <div className="box--text--top">Lv  1</div>
                                    </div>

                                    <div className="box--top--right">
                                        <button className="team--view--btn" onClick={() => {
                                            window.location.href = '/#/records/team?level=1'
                                        }}>view</button>
                                    </div>
                                </div>

                                <div className="data--box--bottom">
                                    <div className="sub--col">
                                        <div className="sub--col--top">{symbol}{parseFloat((recharge.lv1 / rate).toFixed(2))}</div>
                                        <div className="sub--col--bottom">Team Recharge</div>
                                    </div>
                                    <div className="sub--col">
                                        <div className="sub--col--top">{symbol}{parseFloat((commission.lv1 / rate).toFixed(2))}</div>
                                        <div className="sub--col--bottom">My Income (15%)</div>
                                    </div>
                                </div>
                            </div>

                            <div className="data--box">
                                <div className="data--box--top">
                                    <div className="box--top--left">
                                        <div className="box--text--top">Lv  2</div>
                                    </div>

                                    <div className="box--top--right">
                                        <button className="team--view--btn" onClick={() => {
                                            window.location.href = '/#/records/team?level=2'
                                        }}>view</button>
                                    </div>
                                </div>

                                <div className="data--box--bottom">
                                    <div className="sub--col">
                                        <div className="sub--col--top">{symbol}{parseFloat((recharge.lv2 / rate).toFixed(2))}</div>
                                        <div className="sub--col--bottom">Team Recharge</div>
                                    </div>
                                    <div className="sub--col">
                                        <div className="sub--col--top">{symbol}{parseFloat((commission.lv2 / rate).toFixed(2))}</div>
                                        <div className="sub--col--bottom">My Income (3%)</div>
                                    </div>
                                </div>
                            </div>

                            <div className="data--box">
                                <div className="data--box--top">
                                    <div className="box--top--left">
                                        <div className="box--text--top">Lv  3</div>
                                    </div>

                                    <div className="box--top--right">
                                        <button className="team--view--btn" onClick={() => {
                                            window.location.href = '/#/records/team?level=3'
                                        }}>view</button>
                                    </div>
                                </div>

                                <div className="data--box--bottom">
                                    <div className="sub--col">
                                        <div className="sub--col--top">{symbol}{parseFloat((recharge.lv3 / rate).toFixed(2))}</div>
                                        <div className="sub--col--bottom">Team Recharge</div>
                                    </div>
                                    <div className="sub--col">
                                        <div className="sub--col--top">{symbol}{parseFloat((commission.lv3 / rate).toFixed(2))}</div>
                                        <div className="sub--col--bottom">My Income (1%)</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="reward--section">
                    <SecTitle title={'Referral Reward'} />

                    <div className="col--container">
                        <div className="col">
                            {items.map((item, index) => {
                                const rpi = 51 / rate;
                                const rewAmount = index === 0 ? rpi * item : (items[index] - items[index - 1]) * rpi;

                                let isLevelClaimed = level.includes(index + 1);
                                let isAvailableToClaim = size >= item;

                                return (
                                    <div className="reward--box" key={index}>
                                        <div className="reward--box--top">
                                            <div className="box--top--left">
                                                <div className="reward--level">
                                                    <div className="reward--level--icon">
                                                        <BiSolidGift />
                                                    </div>
                                                    <div className="reward--level--text">Task {index + 1}</div>
                                                </div>
                                            </div>

                                            <div className="box--top--right">{size} <span>/{item}</span></div>
                                        </div>

                                        <div className="reward--box--bottom">
                                            <div className="reward--condition">Invite {item} person to make an investment of atleast <span>{symbol}{parseFloat((499 / rate).toFixed(2))}</span>, to receive reward of <span>{symbol}{parseFloat(rewAmount.toFixed(2))}</span></div>

                                            <div className="reward--claim">
                                                <center>
                                                    {isLevelClaimed ? <button className="reward--claim--btn disabled">Claimed</button> : isAvailableToClaim ? <button className="reward--claim--btn enabled" onClick={() => claim(index + 1)}>Claim {symbol}{parseFloat(rewAmount.toFixed(2))}</button> : <button className="reward--claim--btn disabled">Claim {symbol}{parseFloat(rewAmount.toFixed(2))}</button>}
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team