import React, { useState, useEffect, useRef } from 'react'
import '../CSS/Withdraw.css'

import Top2 from '../Components/Top2';
import Loading from '../Components/Loading';
import Error from '../Components/Error';

import axios from 'axios';

import { GiCheckMark } from "react-icons/gi";

const Withdraw = ({ token, rate, symbol }) => {
    const [methods, setMethods] = useState(['TRX', 'USDT'])

    const [balance, setBalance] = useState(0);
    const [withdrawals, setWithdrawals] = useState(0)

    const [rt, setRt] = useState({
        tron: 0,
        usdt: 0
    })

    const [amount, setAmount] = useState(parseFloat((150 / rate).toFixed(2)))
    const [method, setMethod] = useState(methods[0]);

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const [address, setAddress] = useState('');

    const [holderName, setHolderName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [ifsc, setIfsc] = useState('');

    const isFirstRun = useRef(true);

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    const getData = async () => {
        setLoader(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/index/main`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data;
            const status = data.status;

            if (data.isd === '+91') {
                setMethods(['Bank', 'TRX', 'USDT'])
                setMethod('Bank')
            }

            setBalance(data.balance.withdraw)
            setWithdrawals(status.withdrawals)

            setLoader(false);
        } catch (error) {
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';
            displayError(errorMessage);
        }
    };

    useEffect(() => {
        if (isFirstRun.current) {
            getData();
            isFirstRun.current = false;
        }
    }, []);


    async function onWithdraw() {
        try {
            // Check minimum withdrawal amount
            let min = parseFloat((150 / rate).toFixed(2));
            if (amount < min) {
                const errorMessage = `Minimum withdrawal amount is ${symbol}${min}`;
                displayError(errorMessage)

                return
            }

            let max = parseFloat((50000 / rate).toFixed(2));
            if (amount > max) {
                const errorMessage = `Maximum withdrawal amount is ${symbol}${min}`;
                displayError(errorMessage)

                return
            }

            // Validate bank account details if payment method is INR
            if (method === 'Bank') {
                if (!accountNumber) {
                    const errorMessage = 'Please enter a bank account number';
                    displayError(errorMessage);

                    return;
                } else if (accountNumber.length < 9) {
                    const errorMessage = 'Please enter valid bank account number';
                    displayError(errorMessage);

                    return;
                } else if (!holderName) {
                    const errorMessage = 'Please enter account holder name';
                    displayError(errorMessage);

                    return;
                } else if (!ifsc) {
                    const errorMessage = 'Please enter bank IFSC code';
                    displayError(errorMessage);

                    return;
                } else if (ifsc.length !== 11) {
                    const errorMessage = 'Please enter valid IFSC code';
                    displayError(errorMessage)

                    return;
                }
            } else { // Validate TRX address if payment method is crypto
                if (!address) {
                    const errorMessage = 'Please enter TRX TRC20 address';
                    displayError(errorMessage);

                    return
                }
            }

            setLoader(true);

            // Send withdrawal request
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER}/withdraw/request/${method === 'Bank' ? 'inr' : 'crypto'}`,
                { amount, address, ifsc, holderName, accountNumber, type: method },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            // Handle successful withdrawal response
            let data = response.data;
            setBalance(data.balance)
            setWithdrawals(data.withdrawals)

            setLoader(false);
            displayError('Withdrawal Successful')
        } catch (error) {
            // Handle error during withdrawal request
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';
            displayError(errorMessage);
        }
    }

    const getData2 = async () => {
        try {
            const response = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=tron,tether&vs_currencies=usd`);

            setRt({
                tron: response.data.tron.usd,
                usdt: response.data.tether.usd
            })
        } catch (error) {
            return;
        }
    };

    useEffect(() => {
        getData2()
    }, [])

    return (
        <div className='withdraw pg'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            <Top2 page={'Withdraw'} />

            <div className="top--box">
                <div className="box">
                    <div className="box--cover">
                        <div className="col">
                            <div className="col--top">Withdrawable Balance</div>
                            <div className="col--bottom">{symbol}{(balance / rate).toFixed(2)}</div>
                        </div>

                        <div className="col m2">
                            <div className="col--top">My Withdrawals</div>
                            <div className="col--bottom">{symbol}{(withdrawals / rate).toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section--start">
                <div className="col">
                    <div className="col--title">Withdrawal Method</div>

                    <div className="sub--col">
                        {methods.map((item, index) => {
                            const isActive = method === item;

                            return (
                                <div className={isActive ? 'item item--active' : 'item'} key={index} onClick={() => {
                                    if (method === item) return;
                                    setMethod(item)
                                }}>
                                    <div className="item--method">{item}</div>
                                    {isActive && <div className="item--active--sign">
                                        <div className="check--sign"><GiCheckMark /></div>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="col">
                    <div className="col--title">Withdrawal Amount</div>

                    <div className="input--field type1">
                        <div className="input--field--icon">{symbol}</div>
                        <input type="number" placeholder={`${parseFloat((150 / rate).toFixed(2))} - ${parseFloat((50000 / rate).toFixed(2))}`} value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                </div>

                {method === 'Bank' && <div className="col">
                    <div className="col--title">Bank Account Details</div>

                    <div className="input--field type2">
                        <input type="text" placeholder='Holder name' value={holderName} onChange={(e) => setHolderName(e.target.value)} />
                    </div>
                    <div className="input--field type2">
                        <input type="number" placeholder='Account number' value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                    </div>
                    <div className="input--field type2">
                        <input type="text" maxLength={'11'} placeholder='Bank IFSC Code' value={ifsc} onChange={(e) => setIfsc(e.target.value.toUpperCase())} />
                    </div>
                </div>}

                {method !== 'Bank' && <div className="col">
                    <div className="col--title">Withdrawal Address</div>

                    <div className="input--field type2">
                        <input type="text" placeholder={`Enter trc20 ${method} address`} value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                </div>}

                <div className="confirm--withdraw">
                    <center>
                        <button className="withdraw--btn" onClick={onWithdraw}>Withdraw</button>
                        <div className="withdraw--sp--text" onClick={() => window.location.href = '/#/records?type=Withdraw'}>view records</div>
                    </center>
                </div>

                <div className="withdraw--notes">
                    <div className="col4"><b>1.</b> Minimum amount to withdraw is <b>{symbol}{parseFloat((150 / rate).toFixed(2))}</b></div>
                    <div className="col4"><b>2.</b> {methods.includes('Bank') ? `You will be charged 10% taxes on each withdrawal request. On TRX we charge 2 TRX + 5% & for USDT you need to pay 1 USDT + 5%` : 'For every TRX withdrawal we charge 2 TRX + 5% as withdrawal service fee & for USDT we charge 1 USDT + 5%'}</div>
                    <div className="col4"><b>3.</b> {methods.includes('Bank') ? `You can withdraw INR anytime between 8AM - 12PM & 2PM - 6PM. Crypto withdrawals can be done anytime.` : `You can make your crypto withdrawals anytime in a day.`} You can withdrawal only 1 time in a day</div>
                    <div className="col4"><b>4.</b> For every successful withdrawal you make, you have the opportunity to receive a reward by sharing the proof of your withdrawal with our customer support service.</div>
                </div>
            </div>
        </div>
    )
}

export default Withdraw