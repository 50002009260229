import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import Team from './Pages/Team.js';
import Home from './Pages/Home.js';
import Me from './Pages/Me.js';

import Recharge from './Pages/Recharge.js';
import Investments from './Pages/Investments.js';
import Withdraw from './Pages/Withdraw.js';
import Login from './Pages/Login.js';
import Register from './Pages/Register.js';
import Records from './Pages/Records.js';
import Pay from './Pages/Pay.js';
import Team2 from './Pages/Team2.js';

function App() {
  const token = localStorage.getItem('token');
  const rate = localStorage.getItem('rate');
  const symbol = localStorage.getItem('symbol')

  const forceHash = window.location.pathname !== '/' || (window.location.pathname === '/' && window.location.hash === '')

  if (forceHash) {
    if (window.location.pathname !== '/') {
      window.location.replace('/#' + window.location.pathname)
    } else {
      if (token) {
        window.location.replace('/#/index/home')
      } else {
        window.location.replace('/#/user/login')
      }
    }
  }

  return (
    <div className="App">
      <Router>
        {token ? <Routes>
          <Route path='/index/home' element={<Home token={token} symbol={symbol} rate={rate} />} />
          <Route path='/' element={<Home token={token} symbol={symbol} rate={rate} />} />
          <Route path='/my/team' element={<Team token={token} symbol={symbol} rate={rate} />} />
          <Route path='/records/team' element={<Team2 token={token} symbol={symbol} rate={rate} />} />
          <Route path='/mine' element={<Me token={token} symbol={symbol} rate={rate} />} />
          <Route path='/pay' element={<Pay token={token} symbol={symbol} rate={rate} />} />
          <Route path='/recharge' element={<Recharge token={token} symbol={symbol} rate={rate} />} />
          <Route path='/withdraw' element={<Withdraw token={token} symbol={symbol} rate={rate} />} />
          <Route path='/my/investments' element={<Investments token={token} symbol={symbol} rate={rate} />} />
          <Route path='/user/register' element={<Register token={token} symbol={symbol} rate={rate} />} />
          <Route path='/user/login' element={<Login token={token} symbol={symbol} rate={rate} />} />
          <Route path='/records' element={<Records token={token} symbol={symbol} rate={rate} />} />
        </Routes> : <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/user/register' element={<Register />} />
          <Route path='/user/login' element={<Login />} />
        </Routes>}

      </Router>
    </div>
  );
}

export default App;
