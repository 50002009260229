import React, { useState, useEffect, useRef } from 'react'
import '../CSS/Records.css'

import Top2 from '../Components/Top2';
import Loading from '../Components/Loading';
import Error from '../Components/Error';

import axios from 'axios';

import { FiArrowUp, FiArrowDown } from "react-icons/fi";
import { AiFillGift } from "react-icons/ai";
import { FaUser } from "react-icons/fa6";
import { FaPiggyBank } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { PiTicketFill } from "react-icons/pi";
import { MdOutlineCallReceived } from "react-icons/md";

const Records = ({ token, rate, symbol }) => {
    let path = window.location.hash
    const getParams = path.split('?')[1]
    const query = new URLSearchParams(getParams);
    let type = query.get('type')

    const items = ['Financial', 'Recharge', 'Withdraw']

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const [active, setActive] = useState(type && items.includes(type) ? type : 'Financial');
    const [records, setRecords] = useState([]);

    const isFirstRun = useRef(true);

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    const getData = async () => {
        setLoader(true);

        const reqUrl = {
            Financial: 'billing/records',
            Recharge: 'recharge/records',
            Withdraw: 'withdraw/records'
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/${reqUrl[active]}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data;
            setRecords(data.records)


            setLoader(false);
        } catch (error) {
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';
            displayError(errorMessage);
        }
    };

    useEffect(() => {
        if (isFirstRun.current) {
            getData();
            isFirstRun.current = false;
        }
    }, [active]);

    return (
        <div className='records'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            <Top2 page={'Records'} />

            <div className="section--start">
                <div className="records--filter">
                    {items.map((item, index) => {
                        return (
                            <div className={active === item ? 'item item--active' : 'item'} key={index}>
                                <center>
                                    <div className="item--name" onClick={() => {
                                        if (active === item) return;

                                        setRecords([])
                                        isFirstRun.current = true;
                                        setActive(item)
                                    }}>{item}</div>
                                    <div className="dash"></div>
                                </center>
                            </div>
                        )
                    })}
                </div>

                <div className="records--container">
                    {(!records || records.length === 0) && <div className="no--data">
                        <center>
                            <div className="no--data--img">
                                <img src="https://cdn.iconscout.com/icon/premium/png-256-thumb/no-history-4023502-3325735.png?f=webp&w=128" alt="" />
                            </div>
                            <div className="no--data--text">No Record Found</div>
                        </center>
                    </div>}

                    {active === 'Financial' && records.map((item, index) => {
                        const icon = item.item === 'Referral Reward' ? <AiFillGift /> : item.item === 'Daily Return' ? <MdOutlineCallReceived /> : item.item === 'Redeem Code' ? <PiTicketFill /> : item.item === 'Sign-up Bonus' ? <FaUser /> : item.item === 'New Investment' ? <FaPiggyBank /> : item.item === 'Recharge' ? <FiArrowDown /> : item.item === 'Withdraw' ? <FiArrowUp /> : (item.item === 'Lv1 Commission' || item.item === 'Lv2 Commission' || item.item === 'Lv3 Commission') ? <FaUserGroup /> : '';

                        return (
                            <div className="record--box" key={index}>
                                <div className="box--left">
                                    <div className="box--left--left--col">
                                        <div className="box--icon">{icon}</div>
                                    </div>

                                    <div className="box--left--right--col">
                                        <div className="box--col--top">{item.item}</div>
                                        <div className="box--col--bottom">{item.date}</div>
                                    </div>
                                </div>

                                <div className="box--right">
                                    <div className="item--amount">{item.type === true ? '+' : '-'} {symbol}{parseFloat((item.amount / rate).toFixed(2))}</div>
                                </div>
                            </div>
                        )
                    })}

                    {active === 'Recharge' && records.map((item, index) => {
                        const method = item.type === 'UPI' ? 'In Rupee' : 'In Crypto';

                        return (
                            <div className="record--box2" key={index} onClick={() => {
                                if ((item.type === 'UPI' && item.status === 'Pending') || item.type !== 'UPI') {
                                    window.open(item.url)
                                }
                            }}>
                                <div className="box--top">
                                    <div className="box--top--left">
                                        <div className="text--top">order id:</div>
                                        <div className="text--bottom">#{item.order_id}</div>
                                    </div>

                                    <div className="box--top--right">
                                        <div className="order--amount">{symbol}{parseFloat((item.amount / rate).toFixed(2))}</div>
                                    </div>
                                </div>

                                <div className="box--bottom">
                                    <div className="col">
                                        <div className="col--left">Payment Method: </div>
                                        <div className="col--right">{item.type} ({method})</div>
                                    </div>
                                    <div className="col">
                                        <div className="col--left">Recharge Status: </div>
                                        <div className="col--right">{item.status}</div>
                                    </div>
                                    <div className="col">
                                        <div className="col--left">Date & Time: </div>
                                        <div className="col--right">{item.date}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    {active === 'Withdraw' && records.map((item, index) => {
                        const method = item.type === 'UPI' ? 'In Rupee' : 'In Crypto';

                        return (
                            <div className="record--box2" key={index}>
                                <div className="box--top">
                                    <div className="box--top--left">
                                        <div className="text--top">order id:</div>
                                        <div className="text--bottom">#{item.order_id}</div>
                                    </div>

                                    <div className="box--top--right">
                                        <div className="order--amount">{symbol}{parseFloat((item.amount / rate).toFixed(2))}</div>
                                    </div>
                                </div>

                                <div className="box--bottom">
                                    <div className="col">
                                        <div className="col--left">Withdrawal Method: </div>
                                        <div className="col--right">{item?.details?.type} ({method})</div>
                                    </div>
                                    <div className="col">
                                        <div className="col--left">Withdraw Status: </div>
                                        <div className="col--right">{item.status}</div>
                                    </div>
                                    <div className="col">
                                        <div className="col--left">Date & Time: </div>
                                        <div className="col--right">{item.date}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Records