import React, { useState, useEffect } from 'react'
import '../CSS/Auth.css'

import axios from 'axios'

import Loading from '../Components/Loading';
import Error from '../Components/Error'

import { RxCross2 } from "react-icons/rx";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";

const Login = () => {
    const logo = 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/SpaceX-Logo-Xonly.svg/1280px-SpaceX-Logo-Xonly.svg.png';

    const [isdList, setIsdList] = useState([])

    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('');

    const [isd, setIsd] = useState('91')
    const [country, setCountry] = useState('India');

    const [passwordView, setPasswordView] = useState(false)

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const [popup, setPopup] = useState(false);

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    async function getIsdList() {
        setLoader(true)

        axios.get(`${process.env.REACT_APP_SERVER}/isd/list`)
            .then(response => {
                setLoader(false)

                let data = response.data;
                setIsdList(data)
            }).catch(error => {
                setLoader(false);
                displayError(error.response.data.error)
            })
    }

    useEffect(() => {
        getIsdList()
    }, [])

    async function loginAcc() {
        if (!phone) {
            return displayError('Please enter your phone number');
        }

        if (phone.length !== 10 || isNaN(phone)) {
            return displayError('Please enter a valid phone number');
        }

        if (!password) {
            return displayError('Please enter a password');
        }

        if (password.length < 6) {
            return displayError('Password is less than 6 characters');
        }

        setLoader(true)
        await axios.post(`${process.env.REACT_APP_SERVER}/user/login`, {
            phone,
            password,
            isd,
            country
        }).then(response => {
            let data = response.data

            localStorage.setItem('token', data.token)
            localStorage.setItem('rate', data.rate)
            localStorage.setItem('symbol', data.symbol)

            setLoader(false);
            setIsError(true);
            setErrorText('Login Successful');

            setTimeout(() => {
                window.location.href = '/#/index/home';
                window.location.reload()
            }, 1000);
        }).catch(error => {
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Login failed';
            displayError(errorMessage);
        })
    }

    return (
        <div className='auth'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            {popup && <div className="over--screen">
                <div className="popup--close--bg" onClick={() => setPopup(false)}></div>

                <div className="country--popup">
                    <div className="header">
                        <div className="header--left">Select country</div>
                        <div className="header--right" onClick={() => setPopup(false)}>
                            <RxCross2 />
                        </div>
                    </div>

                    <div className="list--container">
                        {isdList.map((item, index) => {
                            let isActive = item.name === country && item.code === isd;

                            return (
                                <div className={isActive ? "list--col active" : "list--col"} key={index} onClick={() => {
                                    setIsd(item.code)
                                    setCountry(item.name)

                                    setPopup(false)
                                }}>
                                    <div className="list--col--left">{item.flag} {item.name}</div>
                                    <div className="list--col--right">+{item.code}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>}

            <div className="auth--section">
                <div className="section--top">
                    <div className="app--logo">
                        <div className="app--logo--img">
                            <img src={logo} alt="" height={'50'} />
                        </div>
                    </div>

                    <div className="des--text">
                        <div className="des--text--top">Welcome Back,</div>
                        <div className="des--text--bottom">Many rewards are waiting for you to collect</div>
                    </div>
                </div>

                <div className="auth--form">
                    <div className="form--title">Sign In</div> <br />

                    <div className="col">
                        <div className="col--title">Phone Number</div>
                        <div className="form--input type2">
                            <div className="in--left" onClick={() => setPopup(true)}>
                                <div className="country--isd">+{isd}</div>
                                <div className="selector--icon">
                                    <IoMdArrowDropdown />
                                </div>
                            </div>
                            <input type="tel" maxLength={'10'} placeholder='Enter your phone number' value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                    </div>

                    <div className="col">
                        <div className="col--title">Password</div>
                        <div className="form--input type3">
                            <input type={passwordView === true ? "text" : "password"} placeholder='Enter your password' value={password} onChange={(e) => setPassword(e.target.value)} />
                            <div className="in--left" onClick={() => setPasswordView(!passwordView)}>
                                {passwordView === true ? <IoEyeOff /> : <IoEye />}
                            </div>
                        </div>
                    </div>

                    <center>
                        <button className="login--btn" onClick={loginAcc}>Login</button>
                        <div className="login--bp">
                            <div className="bpi">Don't have an account?</div>
                            <div className="bpii" onClick={() => window.location.href = '/#/user/register'}>Register now</div>
                        </div>
                    </center>
                </div>
            </div>
        </div>
    )
}

export default Login