import React, { useState, useEffect, useRef } from 'react';
import '../CSS/Auth.css'

import { RiUserFill } from "react-icons/ri";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdJoinFull } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha';

import Loading from '../Components/Loading';
import Error from '../Components/Error';

const DELAY = 1500

const Register = () => {
    const logo = 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/SpaceX-Logo-Xonly.svg/1280px-SpaceX-Logo-Xonly.svg.png';

    let path = window.location.hash
    const getParams = path.split('?')[1]
    const query = new URLSearchParams(getParams);

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('');
    const [refCode, setRefCode] = useState(query.get('inviteCode') ? query.get('inviteCode') : '');

    const [isd, setIsd] = useState('91')
    const [country, setCountry] = useState('India');
    const [symbol, setSymbol] = useState('₹')
    const [rate, setRate] = useState(1)

    const [isdList, setIsdList] = useState([])

    const [passwordView, setPasswordView] = useState(false);

    const [popup, setPopup] = useState(false);

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const [callback, setCallback] = useState("not fired");
    const [captchaToken, setCaptchaToken] = useState("[empty]");
    const [load, setLoad] = useState(false);
    const [expired, setExpired] = useState(false);
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

    const reCaptchaRef = useRef();

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoad(true);
        }, DELAY);
        return () => clearTimeout(timer);
    }, []);

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
        if (captchaToken === null) setExpired(true);
    };

    const asyncScriptOnLoad = () => {
        setCallback("called!");
        setRecaptchaLoaded(true);
    };

    async function getIsdList() {
        setLoader(true)

        axios.get(`${process.env.REACT_APP_SERVER}/isd/list`)
            .then(response => {
                setLoader(false)

                let data = response.data;
                setIsdList(data)
            }).catch(error => {
                setLoader(false);
                displayError(error.response.data.error)
            })
    }

    useEffect(() => {
        getIsdList()
    }, [])

    async function registerAcc() {
        if (!name) {
            return displayError('Please enter your name');
        }

        if (!phone) {
            return displayError('Please enter your phone number');
        }

        if (phone.length !== 10 || isNaN(phone)) {
            return displayError('Please enter a valid phone number');
        }

        if (!password) {
            return displayError('Please enter a password');
        }

        if (password.length < 6) {
            return displayError('Password is less than 6 characters');
        }

        setLoader(true)
        
        /*{load && (<ReCAPTCHA ref={reCaptchaRef} asyncScriptOnLoad={asyncScriptOnLoad} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={handleCaptchaChange} size="invisible" />)} */

        
        
        reCaptchaRef.current.executeAsync().then((value) => {
            axios.post(`${process.env.REACT_APP_SERVER}/user/register`, {
                phone,
                password,
                name,
                inviter: refCode,
                isd,
                country,
                captchaToken: value
            }).then(response => {
                setLoader(false);
                setIsError(true);
                setErrorText('Registration Successful');

                setTimeout(() => {
                    window.location.replace('/#/user/login');
                }, 1000);
            }).catch(error => {
                setLoader(false);
                const errorMessage = error.response?.data?.message || 'Registration failed';
                displayError(errorMessage);
            })
        }); 
    }

    return (
        <div className='auth'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            {popup && <div className="over--screen">
                <div className="popup--close--bg" onClick={() => setPopup(false)}></div>

                <div className="country--popup">
                    <div className="header">
                        <div className="header--left">Select country</div>
                        <div className="header--right" onClick={() => setPopup(false)}>
                            <RxCross2 />
                        </div>
                    </div>

                    <div className="list--container">
                        {isdList.map((item, index) => {
                            let isActive = item.name === country && item.code === isd;

                            return (
                                <div className={isActive ? "list--col active" : "list--col"} key={index} onClick={() => {
                                    setIsd(item.code)
                                    setCountry(item.name)
                                    setSymbol(item.currencySymbol)
                                    setRate(item.rate)

                                    setPopup(false)
                                }}>
                                    <div className="list--col--left">{item.flag} {item.name}</div>
                                    <div className="list--col--right">+{item.code}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>}

            <div className="auth--section">
                <div className="section--top">
                    <div className="app--logo">
                        <div className="app--logo--img">
                            <img src={logo} alt="" height={'50'} />
                        </div>
                    </div>

                    <div className="des--text">
                        <div className="des--text--top">Register to win rewards,</div>
                        <div className="des--text--bottom">Join SpaceX and get free {`${symbol}${parseFloat((51 / rate).toFixed(2))}`} as registration bonus</div>
                    </div>
                </div>

                <div className="auth--form">
                    <div className="form--title">Sign Up</div> <br />

                    <div className="col">
                        <div className="col--title">Name</div>
                        <div className="form--input">
                            <div className="in--left">
                                <RiUserFill />
                            </div>
                            <input type="text" placeholder='Enter your name' value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                    </div>

                    <div className="col">
                        <div className="col--title">Phone Number</div>
                        <div className="form--input type2">
                            <div className="in--left" onClick={() => setPopup(true)}>
                                <div className="country--isd">+{isd}</div>
                                <div className="selector--icon">
                                    <IoMdArrowDropdown />
                                </div>
                            </div>
                            <input type="tel" maxLength={'10'} placeholder='Enter your phone number' value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                    </div>

                    <div className="col">
                        <div className="col--title">Password</div>
                        <div className="form--input type3">
                            <input type={passwordView === true ? "text" : "password"} placeholder='Enter your password' value={password} onChange={(e) => setPassword(e.target.value)} />
                            <div className="in--left" onClick={() => setPasswordView(!passwordView)}>
                                {passwordView === true ? <IoEyeOff /> : <IoEye />}
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="col--title">Referral code</div>
                        <div className="form--input">
                            <div className="in--left">
                                <MdJoinFull />
                            </div>
                            <input type="text" placeholder='Enter invitation code' value={refCode} onChange={(e) => setRefCode(e.target.value)} />
                        </div>
                    </div>

                    {load && (<ReCAPTCHA ref={reCaptchaRef} asyncScriptOnLoad={asyncScriptOnLoad} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={handleCaptchaChange} size="invisible" />)}

                    <center>
                        <button className="login--btn" onClick={registerAcc}>Register</button>
                        <div className="login--bp">
                            <div className="bpi">Already have an account?</div>
                            <div className="bpii" onClick={() => window.location.href = '/#/user/login'}>Login now</div>
                        </div>
                    </center>
                </div>
            </div>
        </div>
    )
}

export default Register