import React, { useState, useEffect, useRef } from 'react'
import '../CSS/Pay.css'

import Loading from '../Components/Loading';
import Error from '../Components/Error';

import copy from 'copy-to-clipboard';
import axios from 'axios';

import { IoCopyOutline } from 'react-icons/io5';

const Pay = ({ token }) => {
    const logo = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCn-pClFrAYFsAvWONyuuPo8wmchKIigZnMg&s';

    let path = window.location.hash
    const getParams = path.split('?')[1]
    const query = new URLSearchParams(getParams);

    let id = query.get('id');

    const [details, setDetails] = useState({})
    const [targetTimestamp, setTargetTimestamp] = useState(/* Set your initial timestamp here */);
    const [timeRemaining, setTimeRemaining] = useState();

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const isFirstRun = useRef(true);

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    const getData = async () => {
        setLoader(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/recharge/details?order_id=${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data;
            let date = data.date

            setDetails(data)
            setTargetTimestamp(date)
            setLoader(false);
        } catch (error) {
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';
            displayError(errorMessage);
        }
    };

    useEffect(() => {
        if (isFirstRun.current) {
            getData();
            isFirstRun.current = false;
        }
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(intervalId);
    }, [targetTimestamp]); // Run the effect whenever targetTimestamp changes

    const calculateTimeRemaining = () => {
        if (!targetTimestamp || isNaN(targetTimestamp)) {
            return { minutes: 0, seconds: 0 };
        }

        const now = new Date();
        const targetDate = new Date(targetTimestamp + (30 * 60000));
        const difference = targetDate - now;

        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { minutes: minutes < 1 ? 0 : minutes, seconds: seconds < 1 ? 0 : seconds };
    };

    function copyText(text) {
        copy(text)
        setIsError(true)
        setErrorText('Copied')

        const timerId = setTimeout(() => {
            setIsError(false)
            setErrorText('')
        }, 1500);

        return () => clearTimeout(timerId);
    }

    return (
        <div className='crypto--pay'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            <div className="crypto--pay--top">
                <div className="order--details">
                    <div className="order--details--left">
                        <div className="platform--logo">
                            <img src={logo} alt="" width={'100%'} />
                        </div>
                    </div>

                    <div className="order--details--right">
                        <div className="platform--name">SpaceX Global</div>
                        <div className="order--id">#{id}</div>
                    </div>
                </div>
            </div>

            <div className="crypto--pay--bottom">
                <div className="payment--details">
                    <div className="payment--details--box">
                        <div className={details.status === 'Pending' ? "box--top pc" : details.status === 'Success' ? "box--top sc" : "box--top fc"}>
                            <div className="box--top--left">
                                <div className="payment--status">{details.status === 'Pending' ? timeRemaining && timeRemaining.minutes === 0 && timeRemaining.seconds === 0 ? 'Order Expired' : 'Expiring In' : details.status === 'Success' ? 'Paid Successfully' : details.status === 'Failed' ? 'Recharge Failed' : ''}</div>
                            </div>
                            <div className="box--top--right">
                                <div className="payment--countdown">{
                                    details.status === 'Pending' ? (
                                        (timeRemaining && timeRemaining.minutes < 10 ? '0' + timeRemaining.minutes : (timeRemaining && timeRemaining.minutes ? timeRemaining.minutes : '00')) +
                                        ':' +
                                        (timeRemaining && timeRemaining.seconds < 10 ? '0' + timeRemaining.seconds : (timeRemaining && timeRemaining.seconds ? timeRemaining.seconds : '00'))
                                    ) : ''
                                }</div>
                            </div>
                        </div>

                        <div className="box--bottom">
                            <div className="col">
                                <div className="col--left">
                                    <div className="col--left--top">{details.status === 'Pending' ? 'Amount to pay' : details.status === 'Success' ? 'Amount Paid' : 'Amount to pay'}</div>
                                    <div className="col--left--bottom">{parseFloat((details.amount)?.toFixed(3))} {details.type} <span>(trc20)</span></div>
                                </div>

                                {details.status === 'Pending' && <div className="col--right">
                                    <div className="copy--btn">
                                        <IoCopyOutline onClick={() => copyText(parseFloat((details.amount)?.toFixed(3)))} />
                                    </div>
                                </div>}
                            </div>

                            <div className="col m2">
                                <div className="col--left">
                                    <div className="col--left--top">Payment Address</div>
                                    <div className="col--left--bottom">{details.address}</div>
                                </div>

                                {details.status === 'Pending' && <div className="col--right">
                                    <div className="copy--btn">
                                        <IoCopyOutline onClick={() => copyText(details.address)} />
                                    </div>
                                </div>}
                            </div>

                            {details.status === 'Pending' ? <div className="si--txt">* IMPORTANT NOTICE: Paying amount less than the order value, can result in your lose. <br /><br />Use the above address to pay once</div> : <div className="si--txt">* IMPORTANT NOTICE: Please dont make any payment on the above address as this order already closed</div>}
                        </div>
                    </div>
                </div>

                <center>
                    <div className="bt--txt">Powered by <span>Asset Avenue</span></div>
                </center>
            </div>
        </div>
    )
}

export default Pay