import React, { useState, useEffect, useRef } from 'react'
import '../CSS/Team2.css'

import Top2 from '../Components/Top2';
import Loading from '../Components/Loading';
import Error from '../Components/Error';

import axios from 'axios';

const Team2 = ({ token, rate, symbol }) => {
    let path = window.location.hash
    const getParams = path.split('?')[1]
    const query = new URLSearchParams(getParams);

    let lvl = query.get('level') && parseFloat(query.get('level'))
    let lv2 = lvl === 3 ? 3 : lvl === 2 ? 2 : 1

    const items = ['Referrals', 'Commission']

    const [active, setActive] = useState('Referrals');

    const [level, setLevel] = useState(lv2);
    const [recharges, setRecharges] = useState(0);
    const [commission, setCommission] = useState(0);
    const [referrals, setReferrals] = useState(0)

    const [records, setRecords] = useState([])

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const isFirstRun = useRef(true);

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    const getData = async () => {
        setLoader(true);

        const type = active === 'Referrals' ? 'users' : 'commission';

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/team/records?level=${level}&type=${type}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data;

            setRecharges(data.investments);
            setCommission(data.commission);
            setRecords(data.records);
            setReferrals(data.referrals)

            setLoader(false);
        } catch (error) {
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';
            displayError(errorMessage);
        }
    };

    useEffect(() => {
        if (isFirstRun.current) {
            getData();
            isFirstRun.current = false;
        }
    }, [active]);

    return (
        <div className='tr pg'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            <Top2 page={`Team Lv${level}`} />

            <div className="top--box">
                <div className="box">
                    <div className="box--cover">
                        <div className="col">
                            <div className="col--top">My Income</div>
                            <div className="col--bottom">{symbol}{(commission / rate).toFixed(2)}</div>
                        </div>

                        <div className="col m2">
                            <div className="col--top">Team Recharges</div>
                            <div className="col--bottom">{symbol}{(recharges / rate).toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section--start">
                <div className="record--filter">
                    {items.map((item, index) => {
                        return (
                            <div className={active === item ? 'item item--active' : 'item'} key={index}>
                                <center>
                                    <div className="item--name" onClick={() => {
                                        if (active === item) return;

                                        setRecords([])
                                        isFirstRun.current = true;
                                        setActive(item)
                                    }}>{item} {item === 'Referrals' && `(${referrals})`}</div>
                                    <div className="dash"></div>
                                </center>
                            </div>
                        )
                    })}
                </div>

                <div className="records--container">
                    {records && active === 'Referrals' && records.map((item, index) => {
                        
                        return (
                            <div className="record--box" key={index}>
                                <div className="record--box--top">
                                    <div className="user--details">
                                        <div className="user--details--left">
                                            <div className="user--pic">
                                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3VOw9ThMu2jJqeneSvh2DXtiCHgbF4IE4vUGdHv0mxeLlyhQfS6z4y9YDH_KBs6Z7nfw&usqp=CAU" height={'100%'} alt="" />
                                            </div>
                                        </div>

                                        <div className="user--details--right">
                                            <div className="user--name">{item.name}</div>
                                            <div className="user--ph">{item.phone}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="record--box--bottom">
                                    <div className="col">
                                        <div className="col--left">User's Investment</div>
                                        <div className="col--right">{symbol}{(item.investments / rate).toFixed(2)}</div>
                                    </div>
                                    <div className="col">
                                        <div className="col--left">My Commission</div>
                                        <div className="col--right">{symbol}{(item.commission / rate).toFixed(2)}</div>
                                    </div>
                                    <div className="col">
                                        <div className="col--left">Registration Date</div>
                                        <div className="col--right">{item.date}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    
                    {records && active === 'Commission' && records.map((item, index) => {
                        return (
                            <div className="record--box2" key={index}>
                                <div className="col">
                                    <div className="col--left">
                                        <div className="ph--col">{item.phone}</div>
                                        <div className="date--col">{item.date}</div>
                                    </div>

                                    <div className="col--right">
                                        <div className="amount--col">{symbol}{parseFloat((item.commission / rate).toFixed(2))}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    {(!records || records.length === 0) && <div className="no--data">
                        <center>
                            <div className="no--data--img">
                                <img src="https://cdn.iconscout.com/icon/premium/png-256-thumb/no-group-here-4022832-3325921.png?f=webp&w=128" alt="" />
                            </div>
                            <div className="no--data--text">No Record Found</div>
                        </center>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default Team2