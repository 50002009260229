import React, { useState, useEffect, useRef} from 'react'
import '../CSS/Me.css'

import { MdKeyboardArrowRight } from "react-icons/md";

import Header from '../Components/Header';
import Top from '../Components/Top';

import axios from 'axios'

import CashPrize from '../Components/CashPrize';
import Loading from '../Components/Loading';
import Error from '../Components/Error';

import { HiBanknotes } from "react-icons/hi2";
import { PiScrollFill } from "react-icons/pi";
import { PiTicketFill } from "react-icons/pi";
import { FaTelegramPlane } from "react-icons/fa";
import { RiCustomerServiceFill } from "react-icons/ri";
import { ImExit } from "react-icons/im";
import { PiDatabaseFill } from "react-icons/pi";
import { FaPeopleGroup } from "react-icons/fa6";

const Me = ({ token, rate, symbol }) => {
    const [redeemPopup, setRedeemPopup] = useState(false);

    const [name, setName] = useState('XXXXX XXX');
    const [phone, setPhone] = useState('+1 ******7890');

    const [totalBalance, setTotalBalance] = useState(0);
    const [mainBalance, setMainBalance] = useState(0);
    const [income, setIncome] = useState(0);
    const [commission, setCommission] = useState(0)

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const isFirstRun = useRef(true);

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    const getData = async () => {
        setLoader(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/index/main`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data;

            setPhone(data.isd + ' ' + data.phone)
            setName(data.name)

            let balance = data.balance

            setTotalBalance(balance.withdraw + balance.recharge);
            setMainBalance(balance.withdraw);
            setCommission(balance.commission);
            setIncome(balance.income);

            //setBalance(data.balance.recharge + data.balance.withdraw);
            //setVip(data.vip);
            //setSpecialPlan(plans.special);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';
            displayError(errorMessage);
        }
    };

    useEffect(() => {
        if (isFirstRun.current) {
            getData();
            isFirstRun.current = false;
        }
    }, []);

    const items = [
        {
            item: 'Financial Records',
            description: 'View and manage all transactions',
            onClick: () => {
                window.location.href = '/#/records?type=Financial'
            },
            icon: <HiBanknotes />
        },
        {
            item: 'Recharge Records',
            description: 'Check your recharge history',
            onClick: () => {
                window.location.href = '/#/records?type=Recharge'
            },
            icon: <PiScrollFill />
        },
        {
            item: 'Payment Records',
            description: 'Track your payment activities',
            onClick: () => {
                window.location.href = '/#/records?type=Withdraw'
            },
            icon: <PiDatabaseFill />
        },
        {
            item: 'Redeem Cash Prize',
            description: 'Redeem your gift codes',
            onClick: () => {
                setRedeemPopup(true)
            },
            icon: <PiTicketFill />
        },
        {
            item: 'Official Telegram Channel',
            description: 'Join our official updates channel',
            icon: <FaTelegramPlane />,
            onClick: () => {
                window.open('https://t.me/+U9M8zBSegL01MTg9')
            }
        },
        {
            item: 'Join Group Chat',
            description: 'Participate in community discussions',
            icon: <FaPeopleGroup />,
            onClick: () => {
                window.open('https://t.me/+z8L-AMl7w1cxMjZl')
            }
        },
        {
            item: 'Customer Support',
            description: 'Get help and support for issues',
            icon: <RiCustomerServiceFill />,
            onClick: () => {
                window.open('https://t.me/spacexshare007')
            }
        },
        {
            item: 'Logout Account',
            description: 'Sign out of your account',
            onClick: () => {
                localStorage.removeItem('token');
                window.location.href = '/#/user/login'
            },
            icon: <ImExit />
        }
    ]

    const handleRedeemCode = (code) => {
        if (!code) {
            displayError('Please enter the redemption code')
            return;
        }

        if (code.length !== 19) {
            displayError('Invalid or expired code')
            return;
        }

        setLoader(true)
        axios.get(`${process.env.REACT_APP_SERVER}/claim/lifafa?code=${code}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                const data = response.data;

                //setBalance(data.recharge + data.withdraw)
                //setWithdrawalBal(data.withdraw)
                setLoader(false)

                displayError('Code claimed successfully');
            }).catch(error => {
                setLoader(false)

                const err = error.response?.data?.message || 'An error occurred';
                displayError(err)
            })
    };
    
    return (
        <div className='me'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            <Header phone={phone} name={name} />
            <Top page={'Me'} />

            {redeemPopup && <div className="redeem--screen">
                <div className="redeem--popup--back" onClick={() => setRedeemPopup(false)}></div>
                <CashPrize onRedeemCode={handleRedeemCode} />
            </div>}

            <div className="section--start">
                <div className="detail--box">
                    <div className="detail--box--cover">
                        <div className="box--content">
                            <div className="balance">
                                <div className="balance--top--text">{symbol}{parseFloat((totalBalance / rate).toFixed(2))}</div>
                                <div className="balance--bottom--text">My Balance</div>
                            </div>

                            <div className="sub--col">
                                <div className="col">
                                    <center>
                                        <div className="col--top--text">{symbol}{parseFloat((mainBalance / rate).toFixed(2))}</div>
                                        <div className="col--bottom--text">Withdrawable</div>
                                    </center>
                                </div>
                                <div className="col">
                                    <center>
                                        <div className="col--top--text">{symbol}{parseFloat((income / rate).toFixed(2))}</div>
                                        <div className="col--bottom--text">My Revenue</div>
                                    </center>
                                </div>
                                <div className="col">
                                    <center>
                                        <div className="col--top--text">{symbol}{parseFloat((commission / rate).toFixed(2))}</div>
                                        <div className="col--bottom--text">Commission's</div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section--menu1"></div>

                <div className="section--menu2">
                    {items.map((item, index) => {
                        return (
                            <div className="col" key={index} onClick={item.onClick}>
                                <div className="col--left">
                                    <div className="col--icon">
                                        {item.icon}
                                    </div>
                                    <div className="col--left--most">
                                        <div className="item--name">{item.item}</div>
                                        <div className="item--dec">{item.description}</div>
                                    </div>
                                </div>

                                <div className="col--right">
                                    <MdKeyboardArrowRight />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Me