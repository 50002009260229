import React, { useState, useEffect, useRef } from 'react'
import '../CSS/Recharge.css'

import Top2 from '../Components/Top2';
import Loading from '../Components/Loading';
import Error from '../Components/Error';

import axios from 'axios';

import { GiCheckMark } from "react-icons/gi";

const Recharge = ({ token, rate, symbol }) => {
    const amo = [499, 2000, 4000, 10000, 20000, 35000, 50000]
    const [methods, setMethods] = useState(['TRX', 'USDT'])
    const [rt, setRt] = useState({
        tron: 0,
        usdt: 0
    })

    const [balance, setBalance] = useState(0);
    const [recharges, setRecharges] = useState(0)

    const [amount, setAmount] = useState(parseFloat((amo[0] / rate).toFixed(2)))
    const [method, setMethod] = useState(methods[0]);

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const isFirstRun = useRef(true);

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    const getData = async () => {
        setLoader(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/index/main`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data;
            const status = data.status;

            if (data.isd === '+91') {
                setMethods(['UPI', 'TRX', 'USDT'])
                setMethod('UPI')
            }

            setBalance(data.balance.withdraw + data.balance.recharge)
            setRecharges(status.deposits)

            setLoader(false);
        } catch (error) {
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';
            displayError(errorMessage);
        }
    };

    useEffect(() => {
        if (isFirstRun.current) {
            getData();
            isFirstRun.current = false;
        }
    }, []);

    const getData2 = async () => {
        try {
            const response = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=tron,tether&vs_currencies=inr`);

            setRt({
                tron: response.data.tron.usd,
                usdt: response.data.tether.usd
            })
        } catch (error) {
            return;
        }
    };

    useEffect(() => {
        getData2()
    }, [])

    async function onRecharge() {
        let minAmount = parseFloat((499 / rate).toFixed(2));

        try {
            if (amount < minAmount) {
                displayError(`Minimum amount to recharge is ${symbol}${minAmount}`);
                return;
            }

            setLoader(true);

            const response = await axios.post(
                `${process.env.REACT_APP_SERVER}/recharge/create/order`,
                { amount, method },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            const data = response.data;
            window.location.href = data.url;

            const timerId = setTimeout(() => {
                setLoader(false);
            }, 3000);

            return () => clearTimeout(timerId);
        } catch (error) {
            setLoader(false);

            const errorMessage = error.response?.data?.message || 'Failed to create recharge order';
            displayError(errorMessage);
        }
    }

    const convertedAmount = () => {
        if (amount > 0 && method !== 'UPI') {
            const tronRate = rt.tron;
            const usdtRate = rt.usdt;

            console.log('amount:', amount);
            console.log('rate:', rate);
            console.log('tronRate:', tronRate);
            console.log('usdtRate:', usdtRate);

            if (method === 'USDT' && usdtRate) {
                return (parseFloat((amount * rate) / usdtRate).toFixed(2) + ' ' + method);
            } else if (tronRate) {
                return (parseFloat((amount * rate) / tronRate).toFixed(2) + ' ' + method);
            }
        }
        return 'Invalid data';
    };

    return (
        <div className='recharge pg'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            <Top2 page={'Recharge'} />

            <div className="top--box">
                <div className="box">
                    <div className="box--cover">
                        <div className="col">
                            <div className="col--top">My Balance</div>
                            <div className="col--bottom">{symbol}{(balance / rate).toFixed(2)}</div>
                        </div>

                        <div className="col m2">
                            <div className="col--top">My Recharges</div>
                            <div className="col--bottom">{symbol}{(recharges / rate).toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="section--start">
                <div className="col">
                    <div className="col--title">Recharge Amount</div>

                    <div className="sub--col">
                        {amo.map((item, index) => {
                            let isActive = parseFloat((item / rate).toFixed(2)) === amount;

                            return (
                                <div className={isActive ? "item item--active" : "item"} key={index} onClick={() => setAmount(parseFloat((item / rate).toFixed(2)))}>
                                    <div className="item--amount">{symbol}{parseFloat((item / rate).toFixed(2))}</div>
                                    {isActive && <div className="item--active--sign">
                                        <div className="check--sign"><GiCheckMark /></div>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>

                    <div className="input--field type1">
                        <div className="input--field--icon">{symbol}</div>
                        <input type="number" placeholder={`${parseFloat((499 / rate).toFixed(2))} - ${parseFloat((50000 / rate).toFixed(2))}`} value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                </div>

                <br />

                <div className="col">
                    <div className="col--title">Payment Method</div>

                    <div className="sub--col">
                        {methods.map((item, index) => {
                            let isActive = method === item;

                            return (
                                <div className={isActive ? 'item item--active' : 'item'} key={index} onClick={() => {
                                    if (method === item) return;
                                    setMethod(item)
                                }}>
                                    <div className="item--method">{item}</div>
                                    {isActive && <div className="item--active--sign">
                                        <div className="check--sign"><GiCheckMark /></div>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="confirm--recharge">
                    <center>
                        <button className="recharge--btn" onClick={onRecharge}>Recharge</button>
                        <div className="recharge--sp--text" onClick={() => window.location.href = '/#/records?type=Recharge'}>view records</div>
                    </center>
                </div>
            </div>
        </div>
    )
}

export default Recharge