import React, { useState, useEffect, useRef } from 'react'
import '../CSS/Home.css'

import axios from 'axios'

import Header from '../Components/Header';
import Top from '../Components/Top';
import SecTitle from '../Components/SecTitle';

import { RiArrowUpCircleFill } from "react-icons/ri";
import { RiArrowDownCircleFill } from "react-icons/ri";
import { FaPiggyBank } from "react-icons/fa6";
import { PiTicketFill } from "react-icons/pi";
import { BsTelegram } from "react-icons/bs";

import { GoBellFill } from "react-icons/go";

import CashPrize from '../Components/CashPrize';
import Loading from '../Components/Loading';
import Error from '../Components/Error';

const Home = ({ token, rate, symbol }) => {
    const [redeemPopup, setRedeemPopup] = useState(false);
    const [investPopup, setInvestPopup] = useState(false)
    
    const [plan, setPlan] = useState([]);

    const [name, setName] = useState('XXXXX XXX');
    const [phone, setPhone] = useState('+1 ******7890');

    const [selectedProduct, setSelectedProduct] = useState({})

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const isFirstRun = useRef(true);

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    const getData = async () => {
        setLoader(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/index/main`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data;

            setPhone(data.isd + ' ' + data.phone)
            setName(data.name)

            //setBalance(data.balance.recharge + data.balance.withdraw);
            //setVip(data.vip);
            //setSpecialPlan(plans.special);
            setPlan(data.plan);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';

            if (error.response && error.response.data.logout === true) {
                window.location.href = '/#/user/login'
                window.location.reload();
            }
            
            displayError(errorMessage);
        }
    };

    useEffect(() => {
        if (isFirstRun.current) {
            getData();
            isFirstRun.current = false;
        }
    }, []);

    async function onInvest(product_id) {
        try {
            setLoader(true);
            await axios.post(`${process.env.REACT_APP_SERVER}/invest`, { product_id }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setLoader(false);
            displayError('Invested Successfully')
        } catch (error) {
            setLoader(false);

            const err = error.response?.data?.message || 'An error occurred';
            displayError(err)
        }
    }

    const handleRedeemCode = (code) => {
        if (!code) {
            displayError('Please enter the redemption code')
            return;
        }

        if (code.length !== 19) {
            displayError('Invalid or expired code')
            return;
        }

        setLoader(true)
        axios.get(`${process.env.REACT_APP_SERVER}/claim/lifafa?code=${code}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                const data = response.data;

                //setBalance(data.recharge + data.withdraw)
                //setWithdrawalBal(data.withdraw)
                setLoader(false)

                displayError('Code claimed successfully');
            }).catch(error => {
                setLoader(false)

                const err = error.response?.data?.message || 'An error occurred';
                displayError(err)
            })
    };


    const items = [
        {
            item: 'Recharge',
            icon: <RiArrowDownCircleFill />,
            onClick: () => {
                window.location.href = '/#/recharge'
            }
        },
        {
            item: 'Withdraw',
            icon: <RiArrowUpCircleFill />,
            onClick: () => {
                window.location.href = '/#/withdraw'
            }
        },
        {
            item: 'Investments',
            icon: <FaPiggyBank />,
            onClick: () => {
                window.location.href = '/#/my/investments'
            }
        },
        {
            item: 'Redeem',
            icon: <PiTicketFill />,
            onClick: () => {
                setRedeemPopup(true)
            }
        },
        {
            item: 'Telegram',
            icon: <BsTelegram />,
            onClick: () => {
                window.open('https://t.me/+U9M8zBSegL01MTg9')
            }
        }
    ]

    return (
        <div className='home'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            <Header phone={phone} name={name} />
            <Top page={'Home'} />

            {redeemPopup && <div className="redeem--screen">
                <div className="redeem--popup--back" onClick={() => setRedeemPopup(false)}></div>
                <CashPrize onRedeemCode={handleRedeemCode} />
            </div>}

            {investPopup && <div className="investment--popup">
                <div className="investment--popup--back" onClick={() => setInvestPopup(false)}></div>
                <div className="investment--popup--box">
                    <div className="investment--box--top"></div>

                    <div className="investment--plan--details">
                        <div className="description">Invest <span>{symbol}{parseFloat((selectedProduct.cost / rate).toFixed(2))}</span> in our Continuous Daily Return Plan and receive a guaranteed daily return of <span>{symbol}{parseFloat((selectedProduct.daily / rate).toFixed(2))}</span>. This plan ensures a steady income stream every day for <span>{selectedProduct.period} day</span>s, providing a total return of <span>{symbol}{parseFloat((selectedProduct.totalIncome / rate).toFixed(2))}</span> over the investment period. Join us to experience reliable and consistent earnings with minimal upfront investment.</div>
                        <div className="grid--col">
                            <div className="col">
                                <div className="col--top">{symbol}{parseFloat((selectedProduct.daily / rate).toFixed(2))}</div>
                                <div className="col--bottom">Daily Income</div>
                            </div>
                            <div className="col">
                                <div className="col--top">{selectedProduct.period} Days</div>
                                <div className="col--bottom">Plan Cycle</div>
                            </div>
                            <div className="col">
                                <div className="col--top">{symbol}{parseFloat((selectedProduct.totalIncome / rate).toFixed(2))}</div>
                                <div className="col--bottom">Total Income</div>
                            </div>
                        </div>

                        <div className="confirm--invest--sec">
                            <div className="plan--cost">
                                <div className="plan--cost--top">{symbol}{(selectedProduct.cost / rate).toFixed(2)}</div>
                                <div className="plan--cost--bottom">Plan Cost</div>
                            </div>

                            <div className="confirm--invest--button">
                                <button className="confirm--invest--btn" onClick={() => {
                                    setInvestPopup(false)
                                    onInvest(selectedProduct.id)
                                }}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            <div className="section--start">
                <div className="balance--box">
                    <div className="balance--box--cover">
                        <div className="box--content"></div>
                    </div>
                </div>

                <div className="notification--box">
                    <div className="notification--icon">
                        <GoBellFill />
                    </div>
                    <div className="notification--text">
                        <marquee behavior="" direction="">Invest with SpaceX Global to earn daily returns on your chosen plans, enjoy instant referral commissions of 15%, 3%, and 2%, and withdraw your earnings anytime in USDT, TRX, or INR. With a minimum deposit starting at {symbol}{parseFloat((499 / rate).toFixed(2))} and a new user bonus of {symbol}{parseFloat((51 / rate).toFixed(2))}, our platform offers a seamless and rewarding investment experience. Join our telegram channel and group to keep yourself updated with our latest news and to get daily gift code.</marquee>
                    </div>
                </div>

                <div className="home--middle--menu">
                    {items.map((item, index) => {
                        return (
                            <div className="col" key={index} onClick={item.onClick}>
                                <center>
                                    <div className="col--icon">{item.icon}</div>
                                    <div className="col--name">{item.item}</div>
                                </center>
                            </div>
                        )
                    })}
                </div>

                <div className="plan--section">
                    <SecTitle title={'Product Hall'} />

                    {plan.map((item, index) => {
                        return (
                            <div className="col" key={index}>
                                <div className="col--top"></div>

                                <div className="plan--details">
                                    <div className="plan--details--left">
                                        <div className="sub--col">
                                            <div className="sub--col--top">{symbol}{parseFloat((item.daily / rate).toFixed(2))}</div>
                                            <div className="sub--col--right">Daily Income</div>
                                        </div>
                                        <div className="sub--col">
                                            <div className="sub--col--top">{symbol}{parseFloat(((item.daily * item.period) / rate).toFixed(2))}</div>
                                            <div className="sub--col--right">Total Income</div>
                                        </div>
                                    </div>

                                    <div className="plan--details--right">
                                        <button className="purchase--btn" onClick={() => {
                                            setSelectedProduct({
                                                daily: item.daily,
                                                period: item.period,
                                                totalIncome: item.daily * item.period,
                                                cost: item.cost,
                                                id: item.id
                                            })

                                            setInvestPopup(true)
                                        }}>{symbol}{parseFloat((item.cost / rate).toFixed(2))}</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Home