import React, { useState } from 'react'

const CashPrize = ({ onRedeemCode }) => {
    const [giftCode, setGiftCode] = useState('')

    async function redeemCode() {
        onRedeemCode(giftCode);
    }

    return (
        <div className='redeem--code--popup'>
            <div className="popup--top--text">Cash Prize</div>
            <div className="popup--input">
                <span>&nbsp;Redeem code</span> <br />
                <input type="text" placeholder='Enter gift code here' value={giftCode} onChange={(e) => setGiftCode(e.target.value)} />
            </div>

            <button className="confirm--btn" onClick={redeemCode}>Confirm</button>
        </div>
    )
}

export default CashPrize