import React from 'react'

const Loading = () => {
    const logo = 'https://cdn.icon-icons.com/icons2/2389/PNG/512/spacex_logo_icon_144865.png';

    return (
        <div className='over--screen'>
            <div className="loading--screen">
                <div className="loader"></div>
                <div className="loader--img">
                    <img src={logo} height={'150'} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Loading