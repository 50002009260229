import React from 'react'
import '../CSS/Components.css'

const Header = ({ name, phone }) => {
    const logo = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFVk2fFUoPJFfriA7kFhWgbyChh44m-IPPvoeCHt3_T4OJgesEkfTdagD2yWNeUiN3f34&usqp=CAU';
    const userPic = 'https://i.pinimg.com/originals/2f/15/f2/2f15f2e8c688b3120d3d26467b06330c.jpg'

    return (
        <div className='main--header'>
            <div className="header--left">
                <div className="app--logo">
                    <img src={logo} alt="" />
                </div>
            </div>

            <div className="header--right">
                <div className="user--detail">
                    <div className="user--name">{name}</div>
                    <div className="user--phone">{phone}</div>
                </div>

                <div className="user--pic">
                    <img src={userPic} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Header