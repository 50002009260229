import React from 'react'

import { useNavigate } from 'react-router-dom';
import { BsArrowLeft } from "react-icons/bs";

const Top2 = ({ page }) => {
    const navigate = useNavigate();

    return (
        <div className='top--bar'>
            <div className="corner--col" onClick={() => navigate(-1)}>
                <BsArrowLeft />
            </div>
            <div className="center--col">{page}</div>
            <div className="corner--col"></div>
        </div>
    )
}

export default Top2