import React from 'react'

const SecTitle = ({ title }) => {
  return (
    <div className='section--title'>
        <div className="section--title--left"></div>
        <div className="section--title--right">{title}</div>
    </div>
  )
}

export default SecTitle