import React, { useState, useEffect, useRef } from 'react'
import '../CSS/Investments.css'

import Top2 from '../Components/Top2';
import Loading from '../Components/Loading';
import Error from '../Components/Error';

import axios from 'axios';

const Investments = ({ token, symbol, rate }) => {
    const [investments, setInvestments] = useState(0);
    const [returns, setReturns] = useState(0);

    const [active, setActive] = useState('Active Invest');

    const [records, setRecords] = useState([]);

    const items = ['Active Invest', 'Expired Invest']

    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const isFirstRun = useRef(true);

    const displayError = (message) => {
        setIsError(true);
        setErrorText(message);

        const timerId = setTimeout(() => {
            setIsError(false);
            setErrorText('');
        }, 1500);

        return () => clearTimeout(timerId);
    };

    const getData = async () => {
        setLoader(true);

        try {
            const expired = active === 'Active Invest' ? false : true;

            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get/investments`, {
                params: { expired },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data;

            setInvestments(data.investments)
            setReturns(data.returns)
            setRecords(data.records)

            setLoader(false);
        } catch (error) {
            setLoader(false);
            const errorMessage = error.response?.data?.message || 'Failed to fetch data';
            displayError(errorMessage);
        }
    };

    useEffect(() => {
        if (isFirstRun.current) {
            getData();
            isFirstRun.current = false;
        }
    }, [active]);

    return (
        <div className='investment pg'>
            {loader && <Loading />}
            {isError && <Error errorMsg={errorText} />}

            <Top2 page={'Investments'} />

            <div className="top--box">
                <div className="box">
                    <div className="box--cover">
                        <div className="col">
                            <div className="col--top">My Investments</div>
                            <div className="col--bottom">{symbol}{(investments / rate).toFixed(2)}</div>
                        </div>

                        <div className="col m2">
                            <div className="col--top">My Returns</div>
                            <div className="col--bottom">{symbol}{(returns / rate).toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section--start">
                <div className="investment--filter">
                    {items.map((item, index) => {
                        return (
                            <div className={active === item ? 'item item--active' : 'item'} key={index}>
                                <center>
                                    <div className="item--name" onClick={() => {
                                        if (active === item) return;

                                        setRecords([])
                                        isFirstRun.current = true;
                                        setActive(item)
                                    }}>{item}</div>
                                    <div className="dash"></div>
                                </center>
                            </div>
                        )
                    })}
                </div>

                <div className="records--container">
                    {records && records.map((item, index) => {
                        let now = new Date(item.date + (1000 * 60 * 60 * 24 * item.period));

                        let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                        let date = ("0" + now.getDate()).slice(-2) + " " + month[now.getMonth()]

                        return (
                            <div className="record--box" key={index}>
                                <div className="record--box--left">
                                    <center>
                                        <div className="box--left--top">My Returns</div>
                                        <div className="box--left--bottom">{symbol}{parseFloat((item.total / rate).toFixed(2))}</div>
                                    </center>
                                </div>

                                <div className="record--box--right">
                                    <div className="box--col">
                                        <div className="box--col--top">My Investments:</div>
                                        <div className="box--col--bottom"><span>{symbol}</span>{parseFloat((item.cost / rate).toFixed(2))}</div>
                                    </div>
                                    <div className="box--col">
                                        <div className="box--col--top">Daily Income:</div>
                                        <div className="box--col--bottom"><span>{symbol}</span>{parseFloat((item.daily / rate).toFixed(2))}</div>
                                    </div>
                                    <div className="box--col">
                                        <div className="box--col--top">Product Runtime:</div>
                                        <div className="box--col--bottom">{item.day} / {item.period}</div>
                                    </div>
                                    <div className="box--col">
                                        <div className="box--col--top">Expiring At:</div>
                                        <div className="box--col--bottom">{date}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {(!records || records.length === 0) && <div className="no--data">
                    <center>
                        <div className="no--data--img">
                            <img src="https://cdni.iconscout.com/illustration/premium/thumb/satellite-network-information-technology-11534933-9428884.png?f=webp" alt="" />
                        </div>
                        <div className="no--data--text">No Investment Found</div>
                    </center>
                </div>}
            </div>
        </div>
    )
}

export default Investments